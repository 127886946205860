.homepage-search.v4 {

    .first-col {
        input {
            width: 94px;
        }
    }

    &.search-selects {


        .input-column {


            input {

            }
        }

        .select-column {
            width: auto;
            height: 50px;

            .SumoSelect {
                > .CaptionCont {
                    border: none;
                    margin-left: 1px;
                    width: auto;
                    text-align: left;
                }
            }
        }
    }
}